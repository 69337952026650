import {
  authGet,
  Escritorio,
  getCookie,
  Usuario,
} from "@prev-front/shared-api";
import { API_HOST_DASHBOARD_V2 } from "./constants";

interface CartaoDeCredito {
  nome: string;
  dataDeVencimento: string;
  bandeira: string;
  numero: string;
  primeirosSeisDigitos: string;
  ultimosQuatroDigitos: string;
}

interface Periodo {
  id: number;
  uuid: string;
  inicio: string;
  fim: string;
  dataDaCobranca: string;
  dataDeAtualizacao: string;
  dataDeCriacao: string;
  motivo: string;
  tiposDeModulo: string[];
}

interface Competencia {
  id: number;
  nome: string;
  estado: Estado;
  descricao: string;
}

interface Especialidade {
  id: number;
  descricao: string;
}

interface Estado {
  id: number;
  nome: string;
  sigla: string;
  regiao: string;
  codigoIbge: string;
  pais: Pais;
}

interface Pais {
  id: number;
  nome: string;
  sigla: string;
}

interface Endereco {
  id: number;
  uf: string;
  cidade: string;
  cep: string;
  endereco: string;
  bairro: string;
  numero: string;
  complemento?: string;
  latitude?: number;
  longitude?: number;
}

interface Membro {
  id: number;
  usuario: Usuario;
  nome: string;
  email: string;
  urlImagemPerfil: string;
  numeroDaOAB: string;
  profissao: string;
  privilegio: string;
  status: string;
  dataDeCriacao: string;
  dataDoUltimoAcesso: string;
}

interface Assinatura {
  id: number
  uuid: string
  cpf: string
  acesso: string
  status: string
  intervalo: string
  meioDePagamento: string
  trial: boolean
  dataDaProximaCobranca: string
  dataDeCriacao: string
  cartaoDeCredito: CartaoDeCredito
  periodo: Periodo
  tipoEmissaoNotaFiscal: string
  tiposDeModulo: [any]
  numeroDeParcelas?: number
}

export interface UserInfoResponse {
  usuario: Usuario;
  escritorio: Escritorio;
  assinatura: Assinatura;
}

export const getUserInfo = async (): Promise<UserInfoResponse> => {
  const cookie = getCookie("prev_session");
  let prev_session = {};
  if (cookie) {
    prev_session = { "X-Global-Session-User-ID": cookie };
  }
  const response = await authGet("/info", API_HOST_DASHBOARD_V2, {
    headers: {
      ...prev_session,
    },
  });
  return response as UserInfoResponse;
};

export interface UserObject {
  id?: number;
  usuario?: Usuario;
  nome?: string;
  email?: string;
  cpf?: string;
  profissao?: string;
  sexo?: string;
  telefone?: string;
  celular?: string;
  dataDeNascimento?: string;
  idade?: number;
  endereco?: Endereco;
  numeroDaOAB?: string;
  privilegio?: string;
  status?: string;
  dataDoUltimoAcesso?: string;
  urlImagemPerfil?: string;
}

export const getProfile = async (): Promise<UserObject> => {
  const data = await authGet("/contas/info");
  return data;
};
