import PrevAxios from "../axiosProvider"
import { getToken } from "../basic"
import { API_HOST_V1, API_AUTH } from "../services/constants"

const isValid = (data) => {
    const receivedTimestamp = data.exp;
    const currentTimestamp = Math.floor(Date.now() / 1000);
    const valid = receivedTimestamp > currentTimestamp
    return valid
}

const wait = (ms) => new Promise((resolve) => setTimeout(resolve, ms));


export const JwtCheck = async () => {
    try {
        const localToken = getToken()
        if (!localToken) {
            localStorage.removeItem('access_token_prev')
            localStorage.removeItem('refresh_token_prev')
            window.location.href = '/login'
            return
        }
        const tokenJson = getProfile(localToken)
        if (!isValid(tokenJson)) {
            const refreshToken = getToken('refresh_token_prev')
            if (!refreshToken) {
                localStorage.removeItem('access_token_prev')
                localStorage.removeItem('refresh_token_prev')
                window.location.href = '/login'
                return
            }
            const jsonToken = getProfile(refreshToken)
            if (isValid(jsonToken)) {
                const response = await getRefreshToken()
                localStorage.setItem('access_token_prev', response.data?.access_token)
                localStorage.setItem('refresh_token_prev', response.data?.refresh_token)
            } else {
                localStorage.removeItem('access_token_prev')
                localStorage.removeItem('refresh_token_prev')
                window.location.href = '/login'
                return
            }
        }
        return true
    } catch (err) {
        localStorage.removeItem('access_token_prev')
        localStorage.removeItem('refresh_token_prev')
        window.location.href = '/login'
        return
    }
}

export async function getRefreshToken() {
    const urlToken = API_HOST_V1 + "/oauth/token";
    const authApp = `Basic ${API_AUTH}`;
    const refreshTokenPrev = getToken("refresh_token_prev");

    var payload = {
        grant_type: "refresh_token",
        refresh_token: refreshTokenPrev,
    };

    const newTokens = await PrevAxios.post(urlToken, new URLSearchParams(payload), {
        headers: {
            Authorization: authApp,
            "Content-Type": "application/x-www-form-urlencoded",
        },
    });

    return newTokens;
}

export const getProfile = (localToken): any => {
    try {
        if (localToken) {
            const base64Url = localToken.split('.')[1]
            const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/')
            const jsonPayload = decodeURIComponent(window.atob(base64).split('').map(function (c) {
                return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)
            }).join(''))

            const data = JSON.parse(jsonPayload)
            return data
        }
    } catch (error) {
        localStorage.removeItem('access_token_prev')
        location.href = '/login'
    }
}