export const TEXT_CASES_TYPES = [
  {
    tipo: 'TENHO_UMA_DOENCA',
    label: 'Tenho uma doença que me impede de trabalhar',
    beneficio: 'Auxílio-Doença',
    tag: 'Benefício por Incapacidade'
  },
  {
    tipo: 'TEMPO_OU_IDADE',
    label: 'Acho que tenho tempo ou idade para me aposentar',
    beneficio: 'Aposentadoria por tempo de contribuição',
    tag: 'Benefício Programável'
  },
  {
    tipo: 'BENEFICIO_CESSADO',
    label: 'Eu estava recebendo um benefício, que foi cortado pelo INSS',
    beneficio: 'Restabelecimento',
    tag: 'Restabelecimento'
  },
  {
    tipo: 'BENEFICIO_REVISAO',
    label: 'Já recebo um benefício do INSS e quero discutir o valor',
    beneficio: 'Revisão',
    tag: 'Revisão'
  },
  {
    tipo: 'FAMILIAR_MORTO_OU_PRESO',
    label: 'Um familiar foi preso ou faleceu',
    beneficio: 'Auxílio reclusão',
    tag: 'Benefício de terceiro'
  },
  {
    tipo: 'GRAVIDEZ',
    label: 'Estou grávida, tive filho ou fiz adoção recentemente',
    beneficio: 'Salário maternidade',
    tag: 'Salário maternidade'
  }
]

export const TIPOS_BENEFICIOS_CALCULO = [
  {
    value: 'APOSENTADORIA_RURAL',
    label: 'Aposentadoria rural'
  },
  {
    value: 'APOSENTADORIA_POR_TEMPO_DE_CONTRIBUICAO',
    label: 'Aposentadoria por tempo de contribuição'
  },
  {
    value: 'APOSENTADORIA_POR_TEMPO_DE_CONTRIBUICAO_PONTOS',
    label: 'Aposentadoria por tempo de contribuição pontos'
  },
  {
    value: 'APOSENTADORIA_POR_IDADE_RURAL',
    label: 'Aposentadoria por idade (Rural)'
  },
  {
    value: 'APOSENTADORIA_POR_TEMPO_DE_CONTRIBUICAO_PROFESSOR',
    label: 'Aposentadoria por Tempo de Contribuição - Professor'
  },
  {
    value: 'APOSENTADORIA_POR_TEMPO_DE_CONTRIBUICAO_PONTOS_PROFESSOR',
    label: 'Aposentadoria por Tempo de Contribuição - Professor (Pontos)'
  },
  {
    value: 'APOSENTADORIA_POR_IDADE_DEFICIENCIA',
    label: 'Aposentadoria por Idade da Pessoa com Deficiência'
  },
  {
    value: 'APOSENTADORIA_POR_TEMPO_DE_CONTRIBUICAO_DEFICIENCIA',
    label: 'Aposentadoria por Tempo de Contribuição da Pessoa com Deficiência'
  },
  {
    value: 'APOSENTADORIA_POR_IDADE',
    label: 'Aposentadoria por idade'
  },
  {
    value: 'APOSENTADORIA_ESPECIAL',
    label: 'Aposentadoria especial'
  },
  {
    value: 'APOSENTADORIA_POR_INVALIDEZ',
    label: 'Aposentadoria por invalidez'
  },
  {
    value: 'APOSENTADORIA_POR_INCAPACIDADE_PERMANENTE',
    label: 'Aposentadoria por incapacidade permanente'
  },
  {
    value: 'AUXILIO_DOENCA',
    label: 'Auxílio-Doença'
  },
  {
    value: 'AUXILIO_ACIDENTE',
    label: 'Auxílio-Acidente'
  },
  {
    value: 'ASSISTENCIAL',
    label: 'Assistencial'
  },
  {
    value: 'PENSAO_POR_MORTE',
    label: 'Pensão por morte'
  },
  {
    value: 'SALARIO_MATERNIDADE',
    label: 'Salário maternidade'
  },
  {
    value: 'AUXILIO_RECLUSAO',
    label: 'Auxílio reclusão'
  }
]

export const TEXT_PETITIONS_TYPES = [
  {
    value: 'ACORDO_CONTRAPROPOSTA',
    label: 'Acordo - Contraproposta',
    tipoDeProcesso: 'JUDICIAL'
  },
  {
    value: 'ANALISES_DE_APOSENTADORIA',
    label: 'Análises de Aposentadoria',
    tipoDeProcesso: 'OUTROS'
  },
  {
    value: 'ANTECIPACAO_DE_TUTELA',
    label: 'Antecipação de Tutela',
    tipoDeProcesso: 'JUDICIAL'
  },
  { value: 'CONTESTACOES', label: 'Contestações', tipoDeProcesso: 'JUDICIAL' },
  { value: 'CONTRARRAZOES', label: 'Contrarrazões', tipoDeProcesso: 'JUDICIAL' },
  {
    value: 'CONTRARRAZOES_ADMINISTRATIVAS',
    label: 'Contrarrazões Administrativas',
    tipoDeProcesso: 'JUDICIAL'
  },
  {
    value: 'CUMPRIMENTO_DE_SENTENCA',
    label: 'Cumprimento de sentença',
    tipoDeProcesso: 'JUDICIAL'
  },
  { value: 'DECLARACOES', label: 'Declarações', tipoDeProcesso: 'JUDICIAL' },
  {
    value: 'DEFESAS_ADMINISTRATIVAS',
    label: 'Defesas Administrativas',
    tipoDeProcesso: 'ADMINISTRATIVO'
  },
  { value: 'MANIFESTACOES', label: 'Manifestações', tipoDeProcesso: 'JUDICIAL' },
  { value: 'PETICOES', label: 'Petições', tipoDeProcesso: 'JUDICIAL' },
  {
    value: 'PETICOES_ADMINISTRATIVAS',
    label: 'Petições Administrativas',
    tipoDeProcesso: 'ADMINISTRATIVO'
  },
  {
    value: 'PETICOES_INICIAIS',
    label: 'Petições Iniciais',
    tipoDeProcesso: 'JUDICIAL'
  },
  {
    value: 'PROPOSTA_DE_ACORDO',
    label: 'Propostas de Acordo',
    tipoDeProcesso: 'JUDICIAL'
  },
  { value: 'RECURSOS', label: 'Recursos', tipoDeProcesso: 'JUDICIAL' },
  {
    value: 'RECURSO_ADMINISTRATIVO',
    label: 'Recursos Administrativos',
    tipoDeProcesso: 'ADMINISTRATIVO'
  },
  { value: 'REPLICAS', label: 'Réplicas', tipoDeProcesso: 'JUDICIAL' },
  {
    value: 'REQUERIMENTO_ADMINISTRATIVO',
    label: 'Requerimento Administrativo',
    tipoDeProcesso: 'ADMINISTRATIVO'
  },
  {
    value: 'RECURSO_DE_APELACAO',
    label: 'Recurso de Apelação',
    tipoDeProcesso: 'JUDICIAL'
  },
  {
    value: 'RECURSO_INOMINADO',
    label: 'Recurso Inominado',
    tipoDeProcesso: 'JUDICIAL'
  },
  {
    value: 'RECURSO_ESPECIAL',
    label: 'Recurso Especial',
    tipoDeProcesso: 'JUDICIAL'
  },
  {
    value: 'RECURSO_EXTRAORDINARIO',
    label: 'Recurso Extraordinário',
    tipoDeProcesso: 'JUDICIAL'
  },
  { value: 'AGRAVOS', label: 'Agravos', tipoDeProcesso: 'JUDICIAL' },
  {
    value: 'AGRAVO_DE_INSTRUMENTO',
    label: 'Agravo de Instrumento',
    tipoDeProcesso: 'JUDICIAL'
  },
  {
    value: 'AGRAVO_INTERNO',
    label: 'Agravo Interno',
    tipoDeProcesso: 'JUDICIAL'
  },
  { value: 'EMBARGOS', label: 'Embargos', tipoDeProcesso: 'JUDICIAL' },
  {
    value: 'EMBARGOS_DE_DECLARACAO',
    label: 'Embargos de Declaração',
    tipoDeProcesso: 'JUDICIAL'
  },
  {
    value: 'EMBARGOS_INFRIGENTES',
    label: 'Embargos Infrigentes',
    tipoDeProcesso: 'JUDICIAL'
  },
  {
    value: 'INCIDENTE_DE_UNIFORMIZACAO',
    label: 'Incidente de Uniformização',
    tipoDeProcesso: 'JUDICIAL'
  }
]

export const TEXT_CORRECAO_MONETARIA = [
  {
    value: 'IGP_DI_INPC_SELIC_MANUAL_CJF',
    label: '<b>Manual CJF</b>: IGP-DI (até 08/2006), INPC (09/2006 - 11/2021) e SELIC (a partir de 12/2021)'
  },
  {
    value: 'IGP_DI_INPC_TR_IPCA_E',
    label: 'IGP-DI (até 03/2006), INPC (04/2006 - 06/2009), TR (07/2009-25/03/2015) e IPCA-E (25/03/2015 - 08/12/2021) e SELIC (a partir de 09/12/2021)'
  },
  {
    value: 'IGP_DI_INPC_TR_IPCA_E2017',
    label: 'IGP-DI (até 03/2006), INPC (04/2006 - 06/2009), TR (07/2009 - 09/2017), IPCA-E (10/2017 - 08/12/2021) e SELIC (a partir de 09/12/2021)'
  },
  {
    value: 'IGP_DI_INPC_IPCA_E',
    label: '<b>Tema 810 STF</b>: IGP-DI (até 03/2006), INPC (04/2006 - 06/2009), IPCA-E (10/2017 - 08/12/2021) e SELIC (a partir de 09/12/2021)'
  },
  {
    value: 'IGP_DI_INPC_TR',
    label: 'IGP-DI (até 03/2006), INPC (04/2006 - 06/2009), TR (07/2009 - 08/12/2021) e SELIC (a partir de 09/12/2021)'
  },
  {
    value: 'IGP_DI_INPC',
    label: '<b>Tema 905 STJ</b>: IGP-DI (até 03/2006), INPC (04/2006 - 08/12/2021) e SELIC (a partir de 09/12/2021)'
  }
]
