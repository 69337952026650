/* eslint-disable @typescript-eslint/no-explicit-any */
import PrevAxios from '../axiosProvider'
import axios from '../axiosProvider'
import { AxiosHeaders, AxiosRequestConfig } from 'axios'

const { API_HOST_V1 } = process.env

const handleError = (error: any): void => {
  throw error
}

export const getToken = (): string => {
  const token = localStorage.getItem('access_token_prev')
  return token || ""
}

export const get = async (path: string, host = API_HOST_V1): Promise<any> => {
  try {
    const url = `${host}${path}`
    const response = await PrevAxios.get(url)
    return response.data
  } catch (error) {
    handleError(error)
  }
}

export const authGet = async (path: string, host = API_HOST_V1, config?: any): Promise<any> => {
  const token = `Bearer ${getToken()}`
  try {
    const url = `${host}${path}`

    const response = await axios.get(url, {
      ...config,
      headers: {
        Authorization: token,
        ...config?.headers
      }
    })
    return response?.data
  } catch (error) {
    handleError(error)
  }
}

export const authGetWithHeader = async (
  path: string,
  host = API_HOST_V1
): Promise<any> => {
  const token = getToken()
  const accessToken = `Bearer ${token}`
  try {
    const url = `${host}${path}`
    const response = await axios.get(url, {
      headers: {
        Authorization: accessToken
      }
    })
    return response
  } catch (error) {
    handleError(error)
  }
}

export const post = async (
  path: string,
  data: any,
  headers: any = {},
  host = API_HOST_V1
): Promise<any> => {
  try {
    const url = `${host}${path}`
    const response = await axios.post(url, data, headers)
    return response.data
  } catch (error) {
    handleError(error)
  }
}

export const authPut = async ({
  path,
  host = API_HOST_V1,
  data,
  config = {}
}: {
  path: string
  host?: string
  data?: any
  config?: any
}): Promise<any> => {
  const accessToken = `Bearer ${getToken()}`

  try {
    const url = `${host}${path}`
    const response = await axios.put(url, data, {
      ...config,
      headers: {
        Authorization: accessToken
      }
    })
    return response.data
  } catch (error) {
    handleError(error)
  }
}

export const authPost = async <T, K>({
  path,
  host = API_HOST_V1,
  data,
  headers,
  config
}: {
  path: string
  host?: string
  data: T
  headers?: AxiosHeaders
  config?: AxiosRequestConfig
}): Promise<K | undefined> => {
  try {
    const accessToken = `Bearer ${getToken()}`
    const url = `${host}${path}`
    const response = await axios.post<K>(url, data, {
      ...config,
      headers: {
        Authorization: accessToken,
        ...headers
      }
    })

    return response.data
  } catch (error) {
    handleError(error)
  }
}

interface AuthDeleteProps {
  path: string
  host?: string
}

export const authDelete = async (props: AuthDeleteProps): Promise<any> => {
  const { path, host = API_HOST_V1 } = props
  const accessToken = `Bearer ${getToken()}`
  const response = await axios.delete(`${host}${path}`, {
    headers: {
      Authorization: accessToken
    }
  })
  return response?.data
}

interface PrevAxiosPut {
  path: string
  host?: string
  data: any
  headers?: any
}

export const put = async (props: PrevAxiosPut): Promise<any> => {
  const { path, host, data, headers } = props
  const response = await axios.put(`${host}${path}`, data, headers)
  return response.data
}
