import PrevAxios, { AxiosError, AxiosResponse } from 'axios';
import { ApiError } from '../error';

const whiteListUrls = ['https://api.homolog.prev.app/v1/oauth/token', 'https://api.homolog.prev.app/v1/contas/senha', 'https://api.homolog.prev.app/v1/contas/senha', "api.mapbox.com", "https://youtube.googleapis.com/"]

let process = true
const requestQueue = new Set<string>();

interface ActivateInterceptorsInterface {
  setRelogin?: (status: boolean) => void
}

export const activateInterceptors = (props: ActivateInterceptorsInterface) => {
  // Interceptor de requisição
  PrevAxios.interceptors.request.use((config) => {
    const requestId = `${config.method || 'get'}:${config.url}`;
    if (!process && !whiteListUrls.some(url => config.url?.includes(url)) && requestQueue.size < 10) {

      if (requestQueue.has(requestId)) {
        return new Promise(() => { }); // Mantém pendente para evitar execução duplicada
      }


      return new Promise((resolve) => {
        requestQueue.add(requestId);
        const waitForProcess = setInterval(() => {
          if (process) {
            clearInterval(waitForProcess); // Para de verificar quando o process é true
            requestQueue.delete(requestId);
            const newToken = localStorage.getItem("access_token_prev")
            if (newToken) {
              config.headers = config.headers || {}
              config.headers['Authorization'] = `Bearer ${newToken}`;
            }
            resolve(config)
          }
        }, 1000);
      });
    }
    return config;
  }, (error) => Promise.reject(error));


  // Interceptor de resposta
  PrevAxios.interceptors.response.use(
    (response: AxiosResponse) => response,
    async (error: AxiosError) => {
      const originalRequest = error.config;

      if (!originalRequest) return Promise.reject('Sem config de erro')

      if (error.response?.status === 401 && process && !whiteListUrls.some(url => originalRequest.url?.includes(url)) && props.setRelogin) {
        props.setRelogin(true); // Ativa o modal de relogin
        process = false; // Pausa novas requisições

        const oldToken = localStorage.getItem("access_token_prev");
        let newToken = localStorage.getItem("access_token_prev");

        // Aguardar o fechamento do modal de relogin e a troca do token
        await new Promise<void>((resolve) => {
          const interval = setInterval(() => {
            newToken = localStorage.getItem("access_token_prev");
            if (newToken !== oldToken) { // Quando o token for alterado (relogin feito)
              process = true; // Permite novas requisições
              clearInterval(interval); // Para o monitoramento
              props.setRelogin && props?.setRelogin(false); // Fecha o modal de relogin
              resolve(); // Resolva a promessa e permita que o fluxo continue
            }
          }, 1000); // Verifica a cada 1 segundo
        });

        // A requisição original será reprocessada aqui, pois o fluxo da função vai continuar
        if (originalRequest) {
          const newResponse = await PrevAxios({
            ...originalRequest,
            headers: {
              ...originalRequest.headers,
              Authorization: `Bearer ${newToken}`,
            },
          });
          return newResponse
        }
      } else if (error.response?.status === 401 && !whiteListUrls.some(url => originalRequest.url?.includes(url)) && requestQueue.size < 5 && props.setRelogin) {
        const requestId = `${originalRequest.method || 'get'}:${originalRequest.url}`;

        if (requestQueue.has(requestId)) {
          const prevApiError = new ApiError(error);
          return await Promise.reject(prevApiError);
        }

        const oldToken2 = localStorage.getItem("access_token_prev");
        let newToken2 = localStorage.getItem("access_token_prev");
        await new Promise<void>((resolve) => {
          requestQueue.add(requestId);
          const interval = setInterval(() => {
            newToken2 = localStorage.getItem("access_token_prev");
            if (newToken2 !== oldToken2) { // Quando o token for alterado (relogin feito)
              clearInterval(interval); // Para o monitoramento
              requestQueue.delete(requestId);
              resolve(); // Resolva a promessa e permita que o fluxo continue
            }
          }, 1000); // Verifica a cada 1 segundo
        });
        if (originalRequest) {
          const newResponse = await PrevAxios({
            ...originalRequest,
            headers: {
              ...originalRequest.headers,
              Authorization: `Bearer ${newToken2}`,
            },
          });
          return newResponse
        }
      }
      const prevApiError = new ApiError(error);
      console.error('API Error:', prevApiError);
      return await Promise.reject(prevApiError);
    }
  );
};

export default PrevAxios;