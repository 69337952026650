/* eslint-disable */
/* tslint:disable */

export type MeioDePagamento = 'CARTAO_DE_CREDITO' | 'BOLETO' | "PIX"
export type IntervaloAssinatura = 'MENSAL' | 'ANUAL'

export interface Token {
    /**
     * Token de acesso
     * @example "XXXXX"
     */
    access_token?: string
    /**
     * tipo de token criado
     * @example "Bearer"
     */
    token_type?: string
    /**
     * validade do token em segundos
     * @example 18000
     */
    expires_in?: number
    /**
     * Atualiza o token existente renovamente a validade.
     * @example "YYY"
     */
    refresh_token?: string
  }
  
  export type CheckoutResponse = Info & {
    pagamento?: Pagamento
  }

  export interface StatusPixResponse {
    status: string
  }
  
  export interface Info {
    usuario?: Usuario
    escritorio?: Escritorio
    assinatura?: Assinatura
  }
  
  export interface JwtTokenResponse {
    /**
     * Subject do JWT
     * @example "154123"
     */
    sub?: string
    assinatura?: JwtAssinatura
    /**
     * Emissor (ou issuer) do JWT
     * @example "https://www.previdenciarista.com.br"
     */
    iss?: string
    conta?: JwtConta
    contexto?: JwtContexto
    privilegio?: Privilegio
    usuario?: Usuario
    /**
     * Data de expiração do token em Unix Time (ou Epoch Time)
     * @example 1657842579
     */
    exp?: number
    /**
     * Data de emissão (issued at) do token em Unix Time (ou Epoch Time)
     * @example 1657842579
     */
    iat?: number
    /**
     * ID da conta
     * @example 553421
     */
    contaId?: number
    /**
     * Identificador do JWT (JWT ID)
     * @example "3ade6707-0694-4c31-a22a-a6ff88226de8"
     */
    jti?: string
    /**
     * Username usado no login. Pode ser CPF ou e-mail do usuário
     * @example "43658451017"
     */
    username?: string
  }
  
  export interface JwtConta {
    /**
     * Status atual da conta (ativa ou não)
     * @example true
     */
    ativo?: boolean
    assinatura?: JwtAssinatura
    escritorio?: JwtEscritorio
    /**
     * CPF da conta
     * @example "43658451017"
     */
    cpf?: string
    privilegio?: Privilegio
    /**
     * ID da conta principal do JWT
     * @example 553421
     */
    contaPrincipalId?: number
    usuario?: JwtUsuario
    /**
     * Nome da proprietário da conta
     * @example "João da Silva"
     */
    nome?: string
    /**
     * ID da conta
     * @example 553421
     */
    id?: number
    /**
     * Email da conta
     * @example "joaodasilva@apitest.com"
     */
    email?: string
    status?: ContaStatus
  }
  
  export interface JwtContexto {
    assinatura?: JwtAssinatura
    escritorio?: JwtEscritorio
    usuario?: JwtUsuario
  }
  
  export interface JwtEscritorio {
    /**
     * O status atual do escritório (ativo ou inativo)
     * @example false
     */
    ativo?: boolean
    /**
     * O nome do escritório
     * @example "João da Silva & Associados"
     */
    nome?: string
    /**
     * O ID do escritório
     * @example 51234
     */
    id?: number
    /**
     * CNPJ do escritório
     * @example "75554489000142"
     */
    cnpj?: string
    /**
     * Primeira "parte" da URL do escritório, como em "slugteste.prev.app"
     * @example "slugteste"
     */
    slug?: string
  }
  
  export interface JwtUsuario {
    /**
     * CPF do usuário no JWT
     * @example "43658451017"
     */
    cpf?: string
    privilegio?: Privilegio
    /**
     * Nome do usuário
     * @example "João da Silva"
     */
    nome?: string
    /**
     * O UUID do usuário
     * @example "b1b857dd-6054-404a-bd8d-322cb2c022f0"
     */
    uuid?: string
    /**
     * Email do usuário
     * @example "joaodasilva@apiteste.com"
     */
    email?: string
  }
  
  export interface JwtAssinatura {
    /**
     * O ID da assinatura.
     * @example 58193
     */
    id?: number
    /**
     * O UUID da assinatura
     * @example "b1b857dd-6054-404a-bd8d-322cb2c022f0"
     */
    uuid?: string
  }
  
  /** @example "ATIVO" */
  export enum ContaStatus {
    ATIVO = 'ATIVO',
    PENDENTE = 'PENDENTE',
    INATIVO = 'INATIVO',
    AGUARDANDO_INATIVACAO = 'AGUARDANDO_INATIVACAO'
  }
  
  export interface Membro {
    /**
     * id do membro
     * @format integer
     * @example "123"
     */
    id?: any
    usuario?: Usuario
    /**
     * Nome do membro
     * @example "João da Silva"
     */
    nome?: string
    /**
     * E-mail do membro
     * @example "email@exemplo.com"
     */
    email?: string
    /**
     * Profissao do membro
     * @example "ADVOGADO"
     */
    profissao?: string
    /**
     * Profissao do membro
     * @example "PROPRIETARIO"
     */
    privilegio?: string
    /**
     * Profissao do membro
     * @example "ATIVO"
     */
    status?: string
    /**
     * Data e hora de criação do membro
     * @format timestamp
     * @example "2022-03-15T21:55:07.935524"
     */
    dataDeCriacao?: string
    /**
     * Data e hora do último acesso do membro
     * @format timestamp
     * @example "2022-03-15T21:55:07.935524"
     */
    dataDoUltimoAcesso?: string
  }
  
  export interface Especialidade {
    /**
     * id da especialidade
     * @example 35
     */
    id?: number
    /**
     * Descrição da especialidade
     * @example "Direito Previdenciário"
     */
    descricao?: string
  }
  
  export interface Pais {
    /**
     * id do país
     * @example 1
     */
    id?: number
    /**
     * Nome do país
     * @example "São Paulo"
     */
    nome?: string
    /**
     * Sigla do país
     * @example "BR"
     */
    sigla?: string
  }
  
  export interface Estado {
    /**
     * id do estado
     * @example 19
     */
    id?: number
    /**
     * Nome do estado
     * @example "São Paulo"
     */
    nome?: string
    /**
     * Sigla do estado
     * @example "SP"
     */
    sigla?: string
    /**
     * Região do estado
     * @example "Sudeste"
     */
    regiao?: string
    /**
     * Código do estado no IBGE
     * @example "35"
     */
    codigoIbge?: string
    pais?: Pais
  }
  
  export interface CidadeDeAtuacao {
    /**
     * id da cidade
     * @example 3643
     */
    id?: number
    /**
     * Nome da cidade
     * @example "São Paulo"
     */
    nome?: string
    estado?: Estado
    /**
     * Descrição do estado
     * @example "São Paulo - SP"
     */
    descricao?: string
  }
  
  export type Membros = Membro[]
  
  export type Especialidades = Especialidade[]
  
  export type CidadesDeAtuacao = CidadeDeAtuacao[]
  
  export interface Escritorio {
    /**
     * ID do escritório
     * @example 1234
     */
    id?: number
    /**
     * Identificador único do escritório
     * @format uuid
     * @example "d2824ace-202d-4fed-af1a-48d7c324e2eb"
     */
    uuid?: string
    /**
     * Nome do escritório
     * @example "João macedo advogados associados"
     */
    nome?: string
    /**
     * Número de funcionários do escritório
     * @example 1
     */
    numeroDeFuncionarios?: number
    /**
     * Slug do catalogo do escritório
     * @example "jmacedoadvg-catalogo-teste"
     */
    slugCatalogo?: string
    cidadesDeAtuacao?: CidadesDeAtuacao
    especialidades?: Especialidades
    membros?: Membros
    /**
     * Flag que indica se o catalogo está ativo
     * @example true
     */
    ativoCatalogo?: boolean
    /**
     * Slug do escritório
     * @example "jmacedoadvg"
     */
    slug?: string
    /**
     * cnpj do escritório
     * @example "80184490000162"
     */
    cnpj?: string
    endereco?: Endereco
  }
  
  export interface CheckoutRequest {
    /**
     * Número de parcelas da trasação no cartão de crédito. Somente será aceito parcelamento em assinaturas cujo o período for ```ANUAL``` e o meio de pagamento for  ```CARTAO_DE_CREDITO```. A quantidade mínima de parcelas é 1 e a máxima são 3. Seu valor default será sempre 1 (Opcional)
     * @default 1
     * @example 1
     */
    numeroDeParcelas?: number
    /**
     * Identificador único do pricing para a criação da assinatura.
     * @format uuid
     * @example "35178e83-deaf-4150-b97a-04dcfc4f1f2c"
     */
    pricingUuid?: string
    assinatura: Assinatura
    usuario: Usuario
  }
  
  export interface Assinatura {
    /**
     * identificador único numérico da assinatura
     * @example 33635
     */
    id?: number
    /**
     * Identificador único da assinatura no formato texto
     * @format uuid
     * @example "9c1eb7e7-90f3-4444-9f85-3d9a5973ae2c"
     */
    uuid?: string
    /**
     * CPF do proprietário da assinatura
     * @example "67359543002"
     */
    cpf?: string
    /**
     * Determina se a recorrência da assinatura está ativa, ou seja, será cobrada novamente no proximo ciclo.
     * @example "ATIVA"
     */
    status?: string
    /**
     * Indica o acesso as funcionaldiades do sistema estão ativos ou suspensos para utilização
     * @example "ATIVO"
     */
    acesso?: string
    /**
     * Define o intervalo de tempo em que serão feitas as cobranças da assinatura.
     * @example "MENSAL"
     */
    intervalo: string
    /** Determina como será realizada as cobranças da assinatura. Se o meio de pagamento for ```CARTAO_DE_CREDITO``` será obrigatório informar os dados do cartão de crédito. */
    meioDePagamento: string
    /**
     * Indica se a assintura encontra-se no priodo trial dentro do range de dias TRIAL.
     * @example true
     */
    trial?: boolean
    /**
     * Determina a próxima data de cobrança da assinatura
     * @format date
     * @example "2021-03-16"
     */
    dataDaProximaCobranca?: string
    /**
     * Indica quando a assinatura foi criada.
     * @format date-time
     * @example "2021-03-01T15:13:42.265202"
     */
    dataDeCriacao?: string
    cartaoDeCredito?: {
      /**
       * Nome do portador do cartão
       * @example "Joao da silva"
       */
      nome: string
      /**
       * Ano e mês de vencimento do cartão
       * @example "2025-03"
       */
      dataDeVencimento: string
      /** @example "VISA" */
      bandeira: string
      /**
       * Numero do cartão
       * @example "4024007197692931"
       */
      numero: string
      /**
       * Primeiros seis digitos do cartão de crédito
       * @example "402400"
       */
      primeirosSeisDigitos?: string
      /**
       * Últimos quatro digitos do cartão de crédito.
       * @example 2931
       */
      ultimosQuatroDigitos?: string
      /**
       * cvv do cartão de crédito
       * @example "123"
       */
      cvv?: string
    }
    periodo?: Periodo
    /** @example ["CALCULO_PETICOES","CATALOGO","CASOS"] */
    tiposDeModulo?: string[]
  }
  
  export interface NovoDescontoRequest {
    /**
     * uuid da assinatura
     * @format uuid
     * @example "50870032-8692-4756-9a36-59d5de8e87b9"
     */
    uuidDaAssinatura: string
    /**
     * Valor do desconto, de 0.1 a 100 se tipo for igual a PORCENTAGEM
     * @format double
     * @min 0.1
     * @example "290,50"
     */
    valor: number
    /**
     * Tipo de desconto, um valor fixo ou uma porcentagem,
     * @default "FIXO"
     */
    tipo?: 'FIXO' | 'PORCENTAGEM'
    /**
     * Quantidade de periodos em que esse desconto se aplicará
     * @min 1
     * @example "3"
     */
    quantidadeDePeriodos: number
  }
  
  export interface NovoDescontoResponse {
    /**
     * id do usuario.
     * @format integer
     * @example "46"
     */
    id?: any
    /**
     * uuid da usuario.
     * @format uuid
     * @example "b8397c34-2d54-4f23-bbac-285f2c2f91b5"
     */
    uuid?: any
    /**
     * id da assinatura.
     * @example "87"
     */
    idDaAssinatura?: number
    /**
     * Valor do desconto, de 0.1 a 100 se tipo for igual a PORCENTAGEM.
     * @format double
     * @min 0.1
     * @example "290,50"
     */
    valor?: number
    /**
     * Quantidade de periodos em que esse desconto se aplicará.
     * @min 1
     * @example "3"
     */
    quantidadeDePeriodos?: number
    /** Tipo de desconto, um valor fixo ou uma porcentagem. */
    tipo?: 'FIXO' | 'PORCENTAGEM'
    /**
     * Se o desconto foi deletado.
     * @example false
     */
    deletado?: boolean
    /**
     * Numero de ultilizações deste desconto
     * @example 0
     */
    numeroDeUtilizacoes?: number
    /**
     * Data em que o desconto foi criado.
     * @format date-time
     * @example "2021-07-28T19:19:48.6305734"
     */
    dataDeCriacao?: string
    /**
     * Data em que o desconto foi atualizado.
     * @format date-time
     * @example "2021-08-01T09:10:08.4563124"
     */
    dataDeAtualizacao?: string
  }
  
  export interface PeriodoRequest {
    /**
     * uuid da assinatura
     * @format uuid
     * @example "50870032-8692-4756-9a36-59d5de8e87b9"
     */
    uuidDaAssinatura: string
    /**
     * Determina o ínicio do período da assinatura
     * @format date
     * @example "2021-03-01"
     */
    inicio: string
    /**
     * Determina o fim do período da assinatura
     * @format date
     * @example "2021-03-31"
     */
    fim?: string
    /**
     * Determina o dia em que será feita a cobrança do período
     * @format date
     * @example "2021-03-16"
     */
    dataDaCobranca?: string
    /**
     * Motivo do periodo de acesso
     * @example "MENSALIDADE"
     */
    motivo?: 'TRIAL' | 'MENSALIDADE' | 'CORTESIA'
  }
  
  export interface Periodo {
    /**
     * identificador único numérico da assinatura
     * @example 33635
     */
    id?: number
    /**
     * Identificador único do período
     * @format uuid
     * @example "51017add-68c4-487b-91ac-38c9421fc154"
     */
    uuid?: string
    /**
     * Determina o ínicio do período da assinatura
     * @format date
     * @example "2021-03-01"
     */
    inicio?: string
    /**
     * Determina o fim do período da assinatura
     * @format date
     * @example "2021-03-31"
     */
    fim?: string
    /**
     * Determina o dia em que será feita a cobrança do período
     * @format date
     * @example "2021-03-16"
     */
    dataDaCobranca?: string
    /**
     * Data de atualização do período
     * @format timestamp
     * @example "2022-07-13T10:11:38.821614"
     */
    dataDeAtualizacao?: string
    /**
     * Data de criação do período
     * @format timestamp
     * @example "2022-07-13T10:11:38.821614"
     */
    dataDeCriacao?: string
    /**
     * Motivo do periodo de acesso
     * @example "TRIAL"
     */
    motivo?: string
    /** @example ["CALCULO_PETICOES","CATALOGO","CASOS"] */
    tiposDeModulo?: string[]
    tipoEmissaoNotaFiscal?: TipoEmissaoNotaFiscal
  }
  
  export enum TipoEmissaoNotaFiscal {
    PROPRIETARIO = 'PROPRIETARIO',
    ESCRITORIO = 'ESCRITORIO'
  }
  
  export enum TipoDeModulo {
    CALCULO_PETICOES = 'CALCULO_PETICOES',
    CASOS = 'CASOS',
    CATALOGO = 'CATALOGO'
  }
  
  export interface Usuario {
    /**
     * identificador único do usúario
     * @format uuid
     * @example "761b6c20-01b7-475d-ac91-c8f33eaec264"
     */
    uuid?: string
    /**
     * Nome do usuário responsável da conta.
     * @example "João da Silva"
     */
    nome: string
    /**
     * A idade do usuário.
     * @example 33
     */
    idade?: number
    /**
     * Email do responsável da conta.
     * @example "joao.silva@gmail.com"
     */
    email: string
    /**
     * CPF do responsável da conta, pode ser com ou sem mascara.
     * @example "48063902599"
     */
    cpf: string
    sexo?: Sexo
    /**
     * Senha alfa númerica
     * @example "101010"
     */
    senha: string
    /**
     * Celular de contato. (Opcional)
     * @example "5511956467897"
     */
    celular?: string
    /**
     * Telefone de contato. (Opicional)
     * @example "551133334444"
     */
    telefone?: string
    /**
     * Data de nascimento
     * @format date
     * @example "1989-05-22"
     */
    dataDeNascimento?: string
    /**
     * Data do último acesso realizado
     * @format timestamp
     * @example "2022-03-15T15:42:48.496521"
     */
    dataDoUltimoAcesso?: string
    privilegio?: Privilegio
    /**
     * Profissão do usuário.
     * @example "ADVOGADO"
     */
    profissao?: string
    /** Endereço do responsável pela assinautra (Opcional) */
    endereco?: Endereco
    /**
     * url da imagem de perfil do usuário
     * @example "https://isudhqiuh1e.cloudfront.net/homolog/img-perfil/78a530ad-0bb8-4654-ab66-303029b911df.jpg"
     */
    urlImagemPerfil?: string
  }
  
  /** @example "PROPRIETARIO" */
  export enum Privilegio {
    PROPRIETARIO = 'PROPRIETARIO',
    ADMINISTRADOR = 'ADMINISTRADOR',
    MEMBRO = 'MEMBRO'
  }
  
  export enum Sexo {
    MASCULINO = 'MASCULINO',
    FEMININO = 'FEMININO'
  }
  
  /** Caso */
  export interface Caso {
    /**
     * Identificado único do caso
     * @example "9d8595f1-6f16-421c-8a85-9e24664fa154"
     */
    uuid?: string
    /**
     * Quantidade atual de reservas realizadas até o momento para este caso.
     * @min 0
     * @default 0
     */
    numeroDeReservas?: number
    /**
     * Retorna a quantidade máxima de reservas para o caso.
     * @min 5
     */
    quantidadeMaximaDeReservas?: number
    /** Indica se o escritório já efetuou a reserva */
    reservado?: boolean
    /**
     * Define o status atual do caso.
     * - PENDENTE -> O caso foi cadastrado porém falta realizar a confirmação de alguns dados.
     * - ABERTO -> O caso está cadastrado com todas as informações validadas.
     * - FECHADO -> O caso está cadastrado porém o cliente não aceita mais ser notificado, ou seu caso foi resolvido.
     */
    status?: 'PENDENTE' | 'ABERTO' | 'FECHADO'
    /**
     * Data e hora da criação do caso.
     * @example "2022-07-21T10:12:12.003615"
     */
    dataDeCriacao?: string
    /**
     * Data e hora da criação do caso.
     * @example "2022-07-21T10:12:12.003615"
     */
    dataDeAbertura?: string
    cliente: ClienteDashboardApi
    questionario?: Questionario
    /** Descrição do caso informada pelo cliente. */
    descricao: string
    /** Data e hora do fechamento do caso. */
    dataDeFechamento?: string
  }
  
  export interface ClienteDashboardApi {
    /**
     * Nome do cliente
     * @example "João da Silva"
     */
    nome: string
    /**
     * Número do CPF do cliente, pode ser com ou sem máscara.
     * @example "991.476.444-43"
     */
    cpf: string
    /** Sexo do cliente. */
    sexo: 'MASCULINO' | 'FEMININO'
    /**
     * data de nascimento do cliente.
     * @format date
     */
    dataDeNascimento: string
    /**
     * Número de inscrição do trabalhado
     * @example "1077342076-3"
     */
    nit?: string
    /**
     * Idade do cliente.
     * @example 33
     */
    idade?: number
    /**
     * Email de contato
     * @example "joao.silva@apitest.com"
     */
    email?: string
    /**
     * Número do para contato formado pelo DDD + número do celular. Aceita apenas dígitos.
     * @example "(11) 99999-8888"
     */
    celular?: string
    endereco?: Endereco
  }
  
  export interface Endereco {
    /**
     * Sigla do estado. (Obrigatório)
     * @example "SP"
     */
    uf:
      | 'AC'
      | 'AL'
      | 'AP'
      | 'AM'
      | 'BA'
      | 'CE'
      | 'DF'
      | 'ES'
      | 'GO'
      | 'MA'
      | 'MT'
      | 'MS'
      | 'MG'
      | 'PA'
      | 'PB'
      | 'PR'
      | 'PE'
      | 'PI'
      | 'RJ'
      | 'RN'
      | 'RS'
      | 'RO'
      | 'RR'
      | 'SC'
      | 'SP'
      | 'SE'
      | 'TO'
    /**
     * Cidade do segurado (Obrigatório)
     * @example "São Paulo"
     */
    cidade: string
    /**
     * Número do cep (Obrigatório)
     * @example "05591020"
     */
    cep?: string
    /**
     * Nome da rua ou avenida do endereço (Opcional)
     * @example "Rua Coronel Serafim Leme da Silva"
     */
    logradouro?: string
    /**
     * Nome do bairro (Opcional)
     * @example "Jardim Bonfiglioli"
     */
    bairro?: string
    /**
     * Número do endereço (Opcional)
     * @example 33
     */
    numero?: string
    /**
     * Complemento do endereço (Opcional)
     * @example "Apto 5, Bloco C"
     */
    complemento?: string
    /**
     * latitude do endereço
     * @format double
     * @example -10.1849
     */
    latitude?: number
    /**
     * longitude do endereço
     * @format double
     * @example -36.8376
     */
    longitude?: number
  }
  
  export interface Questionario {
    /**
     * Já fez contribuição para a previdência social
     * @default false
     */
    jaContribuiuParaPrevidenciaSocial?: boolean
    sexo?: 'MASCULINO' | 'FEMININO'
    tipoDeAjuda:
      | 'TENHO_UMA_DOENCA'
      | 'TEMPO_OU_IDADE'
      | 'BENEFICIO_CESSADO'
      | 'BENEFICIO_REVISAO'
      | 'FAMILIAR_MORTO_OU_PRESO'
      | 'GRAVIDEZ'
    tipoDeDoenca?: 'ACIDENTE_DE_TRABALHO' | 'ACIDENTE_DE_TRABALHO_NO_TRAJETO' | 'DOENCA_COMUM'
    grauDeParentesco?: 'CONJUGE_OU_COMPANHEIRO' | 'FILHO' | 'PAI_OU_MAE' | 'IRMAO'
    beneficioAntigo?:
      | 'APOSENTADORIA_POR_TEMPO_DE_CONTRIBUICAO'
      | 'APOSENTADORIA_POR_IDADE'
      | 'APOSENTADORIA_ESPECIAL'
      | 'AUXILIO_DOENCA'
      | 'AUXILIO_ACIDENTE'
      | 'PENSAO_POR_MORTE'
      | 'AUXILIO_RECLUSAO'
      | 'SALARIO_MATERNIDADE'
      | 'BENEFICIO_ASSISTENCIAL'
    historicoDoSegurado?:
      | 'TRABALHEI_MAIS_DE_30_ANOS'
      | 'TRABALHEI_MAIS_DE_35_ANOS'
      | 'TRABALHEI_25_ANOS_ATIVIDADE_ESPECIAL'
      | 'TRABALHEI_20_ANOS_PROFESSORA'
      | 'TRABALHEI_25_ANOS_PROFESSOR'
    tags?: (
      | 'TRABALHO_URBANO'
      | 'TRABALHO_RURAL'
      | 'FORCAS_ARMADAS'
      | 'SERVICO_PUBLICO'
      | 'RECEBEU_BENEFICIO_INSS_NO_PASSADO'
      | 'NAO_CONTRIBUI_HA_MAIS_DE_6_MESES'
    )[]
    beneficioQueJaRecebeu?:
      | 'APOSENTADORIA_POR_TEMPO_DE_CONTRIBUICAO'
      | 'APOSENTADORIA_POR_IDADE'
      | 'APOSENTADORIA_ESPECIAL'
      | 'AUXILIO_DOENCA'
      | 'AUXILIO_ACIDENTE'
      | 'PENSAO_POR_MORTE'
      | 'AUXILIO_RECLUSAO'
      | 'SALARIO_MATERNIDADE'
      | 'BENEFICIO_ASSISTENCIAL'
    /** @default false */
    pedidoDeConcessao?: boolean
    /** @default false */
    pedidoDeRevisao?: boolean
  }
  
  export interface CasoRequest {
    cliente: ClienteDashboardApi
    questionario: Questionario
    /** Descrição do caso informada pelo cliente. */
    descricao?: string
  }
  
  export interface Pagamento {
    /**
     * Identificador único do boleto
     * @format uuid
     * @example "f900f359-e26e-432a-b394-5b2689339202"
     */
    uuid?: string
    /**
     * @format decimal
     * @example "863.04"
     */
    valor?: number
    /**
     * Status do boleto.
     * @example "PENDENTE"
     */
    status?: 'PENDENTE' | 'SUCESSO' | 'CANCELADO'
    /**
     * Determina qual fluxo deu origem ao pagamento
     * @example "ALTERACAO_PLANO"
     */
    origemDoPagamento?: 'CHECKOUT' | 'REATIVACAO' | 'ALTERACAO_PLANO' | 'PAGAMENTO_RECORRENTE'
    /**
     * Data de criação do pagamento.
     * @format date-time
     * @example "2021-04-01T12:49:41.976863"
     */
    dataDeCriacao?: string
    /**
     * Data da última atualização do pagamento
     * @format date-time
     * @example "2021-04-03T12:49:41.976863"
     */
    dataDeAtualizacao?: string
    /** @example "BOLETO" */
    meioDePagamento?: MeioDePagamento
    boleto?: Boleto
    pix?: Pix
  }

  export interface Pix {
    dataDeCriacao: string
    expiracaoEmSegundos: number
    valor: number
    qrCodeImage: string
    copiaCola: string
    codigoDeAutorizacao: string
  }
  
  export interface Boleto {
    /**
     * Código de barras do boleto
     * @example "38394850500000089900007497130000001731038601"
     */
    codigoDeBarras?: string
    /**
     * @format date
     * @example "2021-04-03"
     */
    dataDeVencimento?: string
    /**
     * URL para download do pdf do boleto
     * @example "https://pay-sandbox.juno.com.br/charge/boleto.pdf?token=1173595:m:95df10d9fcd6564399b92a440e7f15953ecab285679f2d62f14a16403d553051"
     */
    urlDeImpressao?: string
  }
  
  export interface PricingRequest {
    /**
     * Determinar a duração dos períodos que serão criados para recorrência da assinatura.
     * @example "ANUAL"
     */
    intervalo: IntervaloAssinatura
    /**
     * Determina quais serão os módulos contratados. O módulo ```CALCULO_PETICOES``` é obrigatório para todas as assinaturas
     * @example ["CALCULO_PETICOES","CATALOGO","CASOS"]
     */
    tiposDeModulo?: ('CALCULO_PETICOES' | 'CATALOGO' | 'CASOS')[]
  }
  
  export interface ProRata {
    /**
     * Valor bruto da soma de todos os modulos contratados
     * @format decimal
     * @example "1078.80"
     */
    valorBase?: number
    /**
     * Valor total dos descontos aplicados
     * @format decimal
     * @example "215.76"
     */
    valorDoDesconto?: number
    /**
     * Valor final da assinatura valorBase - valorDoDesconto.
     * @format decimal
     * @example "863.04"
     */
    valor?: number
    periodo?: {
      /**
       * Determina o ínicio do período.
       * @format date
       * @example "2021-04-20"
       */
      inicio?: string
      /**
       * Determina o último dia do periodo.
       * @format date
       * @example "2022-04-19"
       */
      fim?: string
      /**
       * Determina quando este período será cobrado. Existem 3 possibilidades...
       * * Uma data no passado, ou seja, o período já está pago
       * * A data de cobrança ser a data atual, ou seja, a cobrança será realizada neste exato momento.
       * * Uma data de cobrança futura, ou seja, o período não está pago, e também não será faturado agoram mas somente depois, neste caso a data da proxima cobrança e data da cobrança do período são iguais.
       * @format date
       * @example "2021-04-20"
       */
      dataDaCobranca?: string
    }
    modulos?: Modulo[]
  }
  
  export interface Modulo {
    /** @example "CASOS" */
    tipo?: 'CALCULO_PETICOES' | 'CATALOGO' | 'CASOS'
    /**
     * Valor bruto da soma de todos os modulos contratados
     * @format decimal
     * @example "1078.80"
     */
    valorBase?: number
    /**
     * Valor total dos descontos aplicados
     * @format decimal
     * @example "215.76"
     */
    valorDoDesconto?: number
    /**
     * Valor final do módulo valorBase - valorDoDesconto.
     * @format decimal
     * @example "863.04"
     */
    valor?: number
  }
  
  export interface TempoDeContribuicaoDashboardApi {
    /**
     * Número de anos do tempo de contribuição
     * @format integer
     * @example "10"
     */
    anos?: number
    /**
     * Número de meses do tempo de contribuição
     * @format integer
     * @example "5"
     */
    meses?: number
    /**
     * Número de dias do tempo de contribuição
     * @format integer
     * @example "26"
     */
    dias?: number
  }
  
  export interface BeneficioMemoriaCalculo {
    /**
     * A competência do salário
     * @example "01-2001"
     */
    dataContribuicao?: string
    /**
     * Valor do salário antes da correção
     * @format decimal
     * @example "1221.00"
     */
    salarioDeContribuicao?: number
    /**
     * Valor do índice de correção monetária
     * @format decimal
     * @example "1.0012"
     */
    indice?: number
    /**
     * Valor do salário com correção monetária aplicada
     * @format decimal
     * @example "2350.00"
     */
    salarioDeContribuicaoCorrigido?: number
    /**
     * Boolean informado se a contribuição foi contabilizado na carência
     * @example true
     */
    carencia?: boolean
    /**
     * Observação do INSS
     * @example "DESCONSIDERADO"
     */
    observacao?: string
    /**
     * Tempo líquido
     * @format integer
     * @example "30"
     */
    tempoLiquido?: number
  }
  
  export interface BeneficioComunicadoDeDecisaoRequest {
    cliente: BeneficioClienteRequest
    beneficio?: {
      /**
       * identificador único do benefício parseado
       * @format uuid
       * @example "6c4547ef-7c03-4e5a-ad2b-bb224a1490fa"
       */
      uuid?: string
      /**
       * Número do benefício
       * @example "6304550174"
       */
      numeroDoBeneficio?: string
    }
    /**
     * Data da decisão do comunicado de decisão
     * @format date
     * @example "2022-06-30"
     */
    dataDecisao?: string
    /**
     * Número do requerimento no INSS
     * @example "199889244"
     */
    numeroRequerimento?: string
    /**
     * Solicitação de Prorrogação de Benefício por Incapacidade
     * @example "Solicitação de Prorrogação de Benefício por Incapacidade"
     */
    assunto?: string
    /**
     * Resultado da decisão no comunicado de decisão
     * @example "Deferimento do Pedido
     * "
     */
    decisao?: string
    /**
     * Motivo do deferimento ou indeferimento no comunicado de decisão
     * @example "Constatação de Incapacidade Laborativa
     * "
     */
    motivo?: string
    /**
     * Fundamentação legal do deferimento ou indeferimento no comunicado de decisão
     * @example "Art. 59 da Lei nº 8.213, de 24/07/1991; Artigos 71 e 77 e § 2º do Art. 78 do Decreto nº\n3.048, de 06/05/1999; § 6º do Art. 75 do Decreto nº 3.048/1999, com redação dada
     * "
     */
    fundamentacaoLegal?: string
    /**
     * Inteiro teor da decisão no comunicado de decisão
     * @example "pelo Decreto nº 8.691/2016.\nEm atenção à sua Solicitação de Prorrogação de Benefício por Incapacidade, apresentada no dia 17/02/2020,\ninformamos que foi reconhecido o direito à prorrogação do benefício. O pagamento do seu benefício será mantido até o\ndia 31/03/2020. Caso considere o prazo insuficiente, o(a) senhor(a) poderá solicitar prorrogação do benefício, dentro do\nprazo de 15 dias antes de sua cessação, pelo número de telefone 135 da Central de Atendimento do INSS ou pela\ninternet no endereço eletrônico: meu.inss.gov.br. Caso considere o prazo suficiente, o(a) senhor(a) poderá retornar\nvoluntariamente ao trabalho, não sendo necessário novo exame médico pericial, conforme parágrafo 6º do art. 75 do\nDecreto nº 3.048/1999, com redação dada pelo Decreto nº 8.691/2016. Desta decisão poderá interpor Recurso, no prazo\nde 30 dias do recebimento desta comunicação, à Junta de Recurso do Conselho de Recursos do Seguro Social -\nJR/CRSS, pelo número de telefone 135 da Central de Atendimento do INSS ou pela internet no endereço eletrônico:\nmeu.inss.gov.br.
     * "
     */
    inteiroTeor?: string
  }
  
  export interface BeneficioComunicadoDeDecisaoResponse {
    /**
     * identificador único do comunicado de decisão adicionado
     * @format uuid
     * @example "6c4547ef-7c03-4e5a-ad2b-bb224a1490fa"
     */
    uuid?: string
    /**
     * Data da decisão do comunicado de decisão
     * @format date
     * @example "2022-06-30"
     */
    dataDecisao?: string
    /**
     * Número do benefício
     * @example "6304550174"
     */
    numeroDoBeneficio?: string
    /**
     * Tipo do benefício
     * @example "AUXILIO_INCAPACIDADE_TEMPORARIA"
     */
    tipoBeneficio?: string
    /**
     * Código do benefício
     * @format integer
     * @example "31"
     */
    codigoBeneficio?: number
    /**
     * Número do requerimento no INSS
     * @example "199889244"
     */
    numeroRequerimento?: string
    /**
     * Solicitação de Prorrogação de Benefício por Incapacidade
     * @example "Solicitação de Prorrogação de Benefício por Incapacidade"
     */
    assunto?: string
    /**
     * Resultado da decisão no comunicado de decisão
     * @example "Deferimento do Pedido
     * "
     */
    decisao?: string
    /**
     * Motivo do deferimento ou indeferimento no comunicado de decisão
     * @example "Constatação de Incapacidade Laborativa
     * "
     */
    motivo?: string
    /**
     * Fundamentação legal do deferimento ou indeferimento no comunicado de decisão
     * @example "Art. 59 da Lei nº 8.213, de 24/07/1991; Artigos 71 e 77 e § 2º do Art. 78 do Decreto nº\n3.048, de 06/05/1999; § 6º do Art. 75 do Decreto nº 3.048/1999, com redação dada
     * "
     */
    fundamentacaoLegal?: string
    /**
     * Inteiro teor da decisão no comunicado de decisão
     * @example "pelo Decreto nº 8.691/2016.\nEm atenção à sua Solicitação de Prorrogação de Benefício por Incapacidade, apresentada no dia 17/02/2020,\ninformamos que foi reconhecido o direito à prorrogação do benefício. O pagamento do seu benefício será mantido até o\ndia 31/03/2020. Caso considere o prazo insuficiente, o(a) senhor(a) poderá solicitar prorrogação do benefício, dentro do\nprazo de 15 dias antes de sua cessação, pelo número de telefone 135 da Central de Atendimento do INSS ou pela\ninternet no endereço eletrônico: meu.inss.gov.br. Caso considere o prazo suficiente, o(a) senhor(a) poderá retornar\nvoluntariamente ao trabalho, não sendo necessário novo exame médico pericial, conforme parágrafo 6º do art. 75 do\nDecreto nº 3.048/1999, com redação dada pelo Decreto nº 8.691/2016. Desta decisão poderá interpor Recurso, no prazo\nde 30 dias do recebimento desta comunicação, à Junta de Recurso do Conselho de Recursos do Seguro Social -\nJR/CRSS, pelo número de telefone 135 da Central de Atendimento do INSS ou pela internet no endereço eletrônico:\nmeu.inss.gov.br.
     * "
     */
    inteiroTeor?: string
    /**
     * Data e hora de criação do benefício
     * @format timestamp
     * @example "2022-03-15T21:55:07.935524"
     */
    dataCriacao?: string
    /**
     * Data e hora de atualização do benefício
     * @format timestamp
     * @example "2022-03-15T21:55:07.935524"
     */
    dataAtualizacao?: string
  }
  
  export interface BeneficioRequest {
    cliente: ClienteDashboardApi
    /**
     * Número do benefício
     * @example "185396518-4"
     */
    numeroDoBeneficio: string
    /**
     * Código do benefício
     * @format integer
     * @example "42"
     */
    codigoBeneficio: number
    /**
     * Tipo do benefício
     * @example "AUXILIO_DOENCA"
     */
    tipoBeneficio: string
    /**
     * Data do início do benefício
     * @format date
     * @example "2022-06-30"
     */
    dataInicioDoBeneficio?: string
    /**
     * Data da concessão do benefício
     * @format date
     * @example "2022-06-30"
     */
    dataConcessaoDoBeneficio?: string
    /**
     * Data do requerimento do benefício
     * @format date
     * @example "2022-06-30"
     */
    dataCessacaoDoBeneficio?: string
    /**
     * Data do requerimento do benefício
     * @format date
     * @example "2022-06-30"
     */
    dataRequerimentoDoBeneficio?: string
    tempoDeContribuicao?: TempoDeContribuicaoDashboardApi
    tempoDeContribuicaoAdicional?: TempoDeContribuicaoDashboardApi
    /**
     * Soma de todas as contribuições
     * @format decimal
     * @example "120000.00"
     */
    somaValor?: number
    /**
     * Soma do número de contribuições
     * @format integer
     * @example "180"
     */
    somaDivisor?: number
    /**
     * Média dos salários de contribuição
     * @format decimal
     * @example "4000.00"
     */
    mediaValor?: number
    /**
     * Valor do salário de benefício
     * @format decimal
     * @example "4000.00"
     */
    salarioDeBeneficio?: number
    /**
     * Valor da Renda Mensal Inicial
     * @format decimal
     * @example "4000.00"
     */
    rmiValor?: number
    /**
     * Tipo de moeda
     * @example "REAL"
     */
    tipoMoeda?: string
    /**
     * Valor do coeficiente do RMI
     * @format decimal
     * @example "0.7"
     */
    rmiCoeficiente?: number
    /**
     * Valor do Fator Previdenciário
     * @format decimal
     * @example "0.6492"
     */
    ftFator?: number
    /**
     * Valor do Alíquota
     * @format decimal
     * @example "0.31"
     */
    ftAlíquota?: number
    /**
     * Valor da expectativa de sobrevida
     * @format decimal
     * @example "25.1"
     */
    ftExpectativaDeSobrevida?: number
    /**
     * Idade do beneficiário
     * @format integer
     * @example "57"
     */
    ftIdade?: number
    /**
     * Número de contribuições
     * @format integer
     * @example "221"
     */
    pbcContribuicoes?: number
    listaMemoriaCalculo?: BeneficioMemoriaCalculo[]
  }
  
  export interface BeneficioResponseDashboardApi {
    /**
     * identificador único do benefício parseado
     * @format uuid
     * @example "6c4547ef-7c03-4e5a-ad2b-bb224a1490fa"
     */
    uuid?: string
    cliente?: {
      /**
       * @format integer
       * @example 12345
       */
      id?: number
    } & ClienteDashboardApi
    /**
     * Número do benefício
     * @example "185396518-4"
     */
    numeroDoBeneficio?: string
    /**
     * Tipo do benefício
     * @example "AUXILIO_DOENCA"
     */
    tipoBeneficio?: string
    /**
     * Código do benefício
     * @format integer
     * @example "42"
     */
    codigoBeneficio?: number
    /**
     * Data do início do benefício
     * @format date
     * @example "2022-06-30"
     */
    dataInicioDoBeneficio?: string
    /**
     * Data da concessão do benefício
     * @format date
     * @example "2022-06-30"
     */
    dataConcessaoDoBeneficio?: string
    /**
     * Data do cessação do benefício
     * @format date
     * @example "2022-06-30"
     */
    dataCessacaoDoBeneficio?: string
    /**
     * Data do requerimento do benefício
     * @format date
     * @example "2022-06-30"
     */
    dataRequerimentoDoBeneficio?: string
    tempoDeContribuicao?: TempoDeContribuicaoDashboardApi
    tempoDeContribuicaoAdicional?: TempoDeContribuicaoDashboardApi
    /**
     * Soma de todas as contribuições
     * @format decimal
     * @example "120000.00"
     */
    somaValor?: number
    /**
     * Soma do número de contribuições
     * @format integer
     * @example "180"
     */
    somaDivisor?: number
    /**
     * Média dos salários de contribuição
     * @format decimal
     * @example "4000.00"
     */
    mediaValor?: number
    /**
     * Valor do salário de benefício
     * @format decimal
     * @example "4000.00"
     */
    salarioDeBeneficio?: number
    /**
     * Valor da Renda Mensal Inicial
     * @format decimal
     * @example "4000.00"
     */
    rmiValor?: number
    /**
     * Valor do coeficiente do RMI
     * @format decimal
     * @example "0.7"
     */
    rmiCoeficiente?: number
    /**
     * Valor do Fator Previdenciário
     * @format decimal
     * @example "0.6492"
     */
    ftFator?: number
    /**
     * Valor do Alíquota
     * @format decimal
     * @example "0.31"
     */
    ftAlíquota?: number
    /**
     * Valor da expectativa de sobrevida
     * @format decimal
     * @example "25.1"
     */
    ftExpectativaDeSobrevida?: number
    /**
     * Idade do beneficiário
     * @format integer
     * @example "57"
     */
    ftIdade?: number
    /**
     * Número de contribuições
     * @format integer
     * @example "221"
     */
    pbcContribuicoes?: number
    listaMemoriaCalculo?: ({
      /**
       * identificador único do salário de contribuição
       * @format uuid
       * @example "6c4547ef-7c03-4e5a-ad2b-bb224a1490fa"
       */
      uuid?: string
      /**
       * identificador do benefício vinculado
       * @format integer
       * @example 123
       */
      beneficioId?: number
    } & BeneficioMemoriaCalculo)[]
    /**
     * Data e hora de criação do benefício
     * @format timestamp
     * @example "2022-03-15T21:55:07.935524"
     */
    dataCriacao?: string
    /**
     * Data e hora de atualização do benefício
     * @format timestamp
     * @example "2022-03-15T21:55:07.935524"
     */
    dataAtualizacao?: string
  }
  
  export interface BeneficioClienteRequest {
    /**
     * Nome do cliente
     * @example "João da Silva"
     */
    nome?: string
    /**
     * Número de inscrição do trabalhador (retorna somente se existir no pdf parseado)
     * @example "1077342076-3"
     */
    nit?: string
    endereco?: {
      /**
       * Sigla do estado. (Obrigatório)
       * @example "SP"
       */
      uf?:
        | 'AC'
        | 'AL'
        | 'AP'
        | 'AM'
        | 'BA'
        | 'CE'
        | 'DF'
        | 'ES'
        | 'GO'
        | 'MA'
        | 'MT'
        | 'MS'
        | 'MG'
        | 'PA'
        | 'PB'
        | 'PR'
        | 'PE'
        | 'PI'
        | 'RJ'
        | 'RN'
        | 'RS'
        | 'RO'
        | 'RR'
        | 'SC'
        | 'SP'
        | 'SE'
        | 'TO'
      /**
       * Cidade do segurado
       * @example "SANTA MARIA"
       */
      cidade?: string
      /**
       * Número do cep
       * @example "97045-410"
       */
      cep?: string
      /**
       * Nome da rua ou avenida do endereço (número, complemento e bairro geralmente estão voltando neste campo)
       * @example "QUATORZE DE JULHO 116 AP 01, ITARARE"
       */
      logradouro?: string
      /**
       * Número do cep
       * @example "97045410"
       */
      cepuUnformatted?: string
    }
  }
  
  export interface BeneficioComunicadoDeDecisaoParserResponse {
    cliente?: BeneficioClienteRequest
    /**
     * Data da decisão do comunicado de decisão
     * @format date
     * @example "2022-06-30"
     */
    dataDecisao?: string
    /**
     * Número do benefício
     * @example "6304550174"
     */
    numeroDoBeneficio?: string
    /**
     * Tipo do benefício
     * @example "AUXILIO_DOENCA"
     */
    tipoBeneficio?: string
    /**
     * Código do benefício
     * @format integer
     * @example "91"
     */
    codigoBeneficio?: number
    /**
     * Número do requerimento no INSS
     * @example "199889244"
     */
    numeroRequerimento?: string
    /**
     * Solicitação de Prorrogação de Benefício por Incapacidade
     * @example "199889244"
     */
    assunto?: string
    /**
     * Resultado da decisão no comunicado de decisão
     * @example "Deferimento do Pedido
     * "
     */
    decisao?: string
    /**
     * Motivo do deferimento ou indeferimento no comunicado de decisão
     * @example "Constatação de Incapacidade Laborativa
     * "
     */
    motivo?: string
    /**
     * Fundamentação legal do deferimento ou indeferimento no comunicado de decisão
     * @example "Art. 59 da Lei nº 8.213, de 24/07/1991; Artigos 71 e 77 e § 2º do Art. 78 do Decreto nº\n3.048, de 06/05/1999; § 6º do Art. 75 do Decreto nº 3.048/1999, com redação dada
     * "
     */
    fundamentacaoLegal?: string
    /**
     * Inteiro teor da decisão no comunicado de decisão
     * @example "pelo Decreto nº 8.691/2016.\nEm atenção à sua Solicitação de Prorrogação de Benefício por Incapacidade, apresentada no dia 17/02/2020,\ninformamos que foi reconhecido o direito à prorrogação do benefício. O pagamento do seu benefício será mantido até o\ndia 31/03/2020. Caso considere o prazo insuficiente, o(a) senhor(a) poderá solicitar prorrogação do benefício, dentro do\nprazo de 15 dias antes de sua cessação, pelo número de telefone 135 da Central de Atendimento do INSS ou pela\ninternet no endereço eletrônico: meu.inss.gov.br. Caso considere o prazo suficiente, o(a) senhor(a) poderá retornar\nvoluntariamente ao trabalho, não sendo necessário novo exame médico pericial, conforme parágrafo 6º do art. 75 do\nDecreto nº 3.048/1999, com redação dada pelo Decreto nº 8.691/2016. Desta decisão poderá interpor Recurso, no prazo\nde 30 dias do recebimento desta comunicação, à Junta de Recurso do Conselho de Recursos do Seguro Social -\nJR/CRSS, pelo número de telefone 135 da Central de Atendimento do INSS ou pela internet no endereço eletrônico:\nmeu.inss.gov.br.
     * "
     */
    inteiroTeor?: string
  }
  
  export interface BeneficioCartaConcessaoParserResponse {
    /**
     * identificador único do benefício parseado
     * @format uuid
     * @example "6c4547ef-7c03-4e5a-ad2b-bb224a1490fa"
     */
    uuid?: string
    cliente?: {
      /**
       * Nome do cliente
       * @example "João da Silva"
       */
      nome?: string
      /**
       * CPF do Cliente (retorna somente se existir no pdf parseado)
       * @example "82025061072"
       */
      cpf?: string
      /**
       * Número de inscrição do trabalhador (retorna somente se existir no pdf parseado)
       * @example "1077342076-3"
       */
      nit?: string
    }
    /**
     * Número do benefício
     * @example "185396518-4"
     */
    numeroDoBeneficio?: string
    /**
     * Tipo do benefício
     * @example "AUXILIO_DOENCA"
     */
    tipoBeneficio?: string
    /**
     * Código do benefício
     * @format integer
     * @example "42"
     */
    codigoBeneficio?: number
    /**
     * Data do início do benefício
     * @format date
     * @example "2022-06-30"
     */
    dataInicioDoBeneficio?: string
    /**
     * Data da concessão do benefício
     * @format date
     * @example "2022-06-30"
     */
    dataConcessaoDoBeneficio?: string
    /**
     * Data do requerimento do benefício
     * @format date
     * @example "2022-06-30"
     */
    dataRequerimentoDoBeneficio?: string
    tempoDeContribuicao?: TempoDeContribuicaoDashboardApi
    tempoDeContribuicaoAdicional?: TempoDeContribuicaoDashboardApi
    /**
     * Soma de todas as contribuições
     * @format decimal
     * @example "120000.00"
     */
    somaValor?: number
    /**
     * Soma do número de contribuições
     * @format decimal
     * @example "180"
     */
    somaDivisor?: number
    /**
     * Média dos salários de contribuição
     * @format decimal
     * @example "4000.00"
     */
    mediaValor?: number
    /**
     * Valor do salário de benefício
     * @format decimal
     * @example "4000.00"
     */
    salarioDeBeneficio?: number
    /**
     * Valor da Renda Mensal Inicial
     * @format decimal
     * @example "4000.00"
     */
    rmiValor?: number
    tipoMoeda?: TipoMoeda
    /**
     * Valor do coeficiente do RMI
     * @format decimal
     * @example "0.7"
     */
    rmiCoeficiente?: number
    /**
     * Valor do Fator Previdenciário
     * @format decimal
     * @example "0.6492"
     */
    ftFator?: number
    /**
     * Valor do Alíquota
     * @format decimal
     * @example "0.31"
     */
    ftAlíquota?: number
    /**
     * Valor da expectativa de sobrevida
     * @format decimal
     * @example "25.1"
     */
    ftExpectativaDeSobrevida?: number
    /**
     * Idade do beneficiário
     * @format integer
     * @example "57"
     */
    ftIdade?: number
    /**
     * Número de contribuições
     * @format integer
     * @example "221"
     */
    pbcContribuicoes?: number
    listaMemoriaCalculo?: BeneficioMemoriaCalculo[]
  }
  
  export interface TipoMoeda {
    /** Tipos de moeda */
    moeda?: 'REAL' | 'CRUZEIRO'
  }
  
  export interface TipoDoBeneficio {
    /** Tipos do benefício */
    beneficio?:
      | 'APOSENTADORIA_POR_TEMPO_DE_CONTRIBUICAO'
      | 'APOSENTADORIA_POR_IDADE'
      | 'APOSENTADORIA_ESPECIAL'
      | 'APOSENTADORIA_INCAPACIDADE_PERMANENTE'
      | 'AUXILIO_DOENCA'
      | 'AUXILIO_ACIDENTE'
      | 'AUXILIO_INCAPACIDADE_TEMPORARIA_PREVIDENCIA'
      | 'AUXILIO_INCAPACIDADE_TEMPORARIA_ACIDENTE'
      | 'PENSAO_POR_MORTE'
      | 'AUXILIO_RECLUSAO'
      | 'SALARIO_MATERNIDADE'
      | 'BENEFICIO_ASSISTENCIAL_IDOSO'
      | 'BENEFICIO_ASSISTENCIAL_PORTADOR_DEFICIENCIA'
      | 'NENHUM'
  }
  
  export interface PricingResponse {
    /**
     * identificar único do pricing/pedido cálculado.
     * @format uuid
     */
    uuid: string
    /**
     * Valor bruto da soma de todos os modulos contratados
     * @format decimal
     * @example "1078.80"
     */
    valorBase: number
    /**
     * Valor total dos descontos aplicados
     * @format decimal
     * @example "215.76"
     */
    valorDoDesconto: number
    /**
     * Valor final da assinatura valorBase - valorDoDesconto.
     * @format decimal
     * @example "863.04"
     */
    valor: number
    assinatura: {
      /**
       * Determinar a duração dos períodos que serão criados para recorrência da assinatura.
       * @example "ANUAL"
       */
      intervalo: IntervaloAssinatura
      /**
       * Determina quando o sistema fará novamente uma cobrança da assinatura
       * @format date
       * @example "2021-03-16"
       */
      dataDaProximaCobranca: string
      periodo: {
        /**
         * Determina o ínicio do período.
         * @format date
         * @example "2021-04-20"
         */
        inicio: string
        /**
         * Determina o último dia do periodo.
         * @format date
         * @example "2022-04-19"
         */
        fim: string
        /**
         * Determina quando este período será cobrado. Existem 3 possibilidades...
         * * Uma data no passado, ou seja, o período já está pago
         * * A data de cobrança ser a data atual, ou seja, a cobrança será realizada neste exato momento.
         * * Uma data de cobrança futura, ou seja, o período não está pago, e também não será faturado agoram mas somente depois, neste caso a data da proxima cobrança e data da cobrança do período são iguais.
         * @format date
         * @example "2021-04-20"
         */
        dataDaCobranca: string
      }
      /** @example ["CALCULO_PETICOES","CATALOGO","CASOS"] */
      tiposDeModulo: ('CALCULO_PETICOES' | 'CATALOGO' | 'CASOS')[]
    }
    tabelaDePrecoModulos: TabelaDePrecoModulos
    proRata?: ProRata
    parcelas: [any]
  }
  
  export interface TabelaDePrecoModulos {
    /** @example {"$ref":"#/components/examples/CalculoEPeticoesResponse"} */
    calculoEPeticoes?: TipoModulo
    /** @example {"$ref":"#/components/examples/CasosResponse"} */
    casos?: TipoModulo
    /** @example {"$ref":"#/components/examples/CatalogoResponse"} */
    catalogo?: TipoModulo
  }
  
  export interface TipoModulo {
    tipo?: 'CALCULO_PETICOES' | 'CASOS' | 'CATALOGO'
    /**
     * Valor base do módulo
     * @format double
     */
    valorBase?: number
    /**
     * Valor em % do desconto do módulo
     * @format double
     */
    porcentagemDesconto?: number
    /**
     * Valor calculado do desconto
     * @format double
     */
    valorDesconto?: number
    /**
     * Valor final do módulo
     * @format double
     */
    valorLiquido?: number
  }
  
  export type InfoDetalhado = Info & {
    usuario?: Usuario & {
      /**
       * Sessões atribuidas ao usuário
       * @example ["9eea96fb-ea94-4b8a-bf3e-e1ac2d3dc908"]
       */
      sessoes?: string[]
    }
  }
  
  export interface NovoTitular {
    /**
     * CPF do novo titular.
     * @example "13033494005"
     */
    cpfNovoTitular: string
    /**
     * Endereço de e-mail do novo titular.
     * @example "usuario.teste@dominio.com"
     */
    emailNovoTitular: string
    /**
     * Nome do titular.
     * @example "Joao da Silva"
     */
    nomeNovoTitular?: string
    /**
     * ID do membro ou administrator novo titular
     * @example "2"
     */
    idDoMembro?: string
  }
  
  export interface NovoPagamentoRequest {
    /**
     * Determina qual será o meio de pagamento padrão utilizado para efetuar o pagamento.
     * @example "CARTAO_DE_CREDITO"
     */
    meioDePagamento: MeioDePagamento
    /**
     * Número de parcelas do cartão de crédito. O valor deve ser maior que zero. Caso não informado valor default é 1 (Opcional)
     * @default 1
     * @example 1
     */
    numeroDeParcelas?: number
    cartaoDeCredito?: CartaoDeCredito
    /**
     * Define o intervalo de tempo em que serão feitas as cobranças da assinatura.
     * @example "MENSAL"
     */
    intervalo?: IntervaloAssinatura
    /** @example ["CALCULO_PETICOES","CATALOGO","CASOS"] */
    tiposDeModulo?: ('CALCULO_PETICOES' | 'CATALOGO' | 'CASOS')[]
  }
  
  export interface NovoPagamentoComPricingRequest {
    /**
     * Identificador único do pricing para a criação da assinatura.
     * @format uuid
     * @example "35178e83-deaf-4150-b97a-04dcfc4f1f2c"
     */
    pricingUuid: string
    /**
     * Determina qual será o meio de pagamento padrão utilizado para efetuar o pagamento.
     * @example "CARTAO_DE_CREDITO"
     */
    meioDePagamento: MeioDePagamento
    /**
     * Número de parcelas do cartão de crédito. O valor deve ser maior que zero. Caso não informado valor default é 1 (Opcional)
     * @default 1
     * @example 1
     */
    numeroDeParcelas?: number
    cartaoDeCredito?: CartaoDeCredito
    /**
     * Define o intervalo de tempo em que serão feitas as cobranças da assinatura.
     * @example "MENSAL"
     */
    intervalo?: IntervaloAssinatura
    /** @example ["CALCULO_PETICOES","CATALOGO","CASOS"] */
    tiposDeModulo?: ('CALCULO_PETICOES' | 'CATALOGO' | 'CASOS')[]
  }
  
  export interface AlteraMeioDePagamentoRequest {
    /**
     * Determina qual será o meio de pagamento padrão utilizado para efetuar o pagamento.
     * @example "CARTAO_DE_CREDITO"
     */
    meioDePagamento: MeioDePagamento
    cartaoDeCredito?: CartaoDeCredito
  }
  
  export interface NovaFaturaRequest {
    /**
     * Indica qual período deve ser faturado
     * @default 1
     * @example 5678
     */
    idDoPeriodo: number
    /**
     * Determina qual será o meio de pagamento utilizado para a emissão de fatura. Caso não informado, usará o padrão default da assinatura.
     * @example "BOLETO"
     */
    meioDePagamento?: MeioDePagamento
  }
  
  export interface PagarFaturaTokenRequest {
    /**
     * Meio de pagamento que será usado para o pagamento
     * @example "BOLETO"
     */
    meioDePagamento: MeioDePagamento
    /**
     * UUID usado para identificar o pagamento
     * @example "462b205f-7f02-4ffe-9f33-3da1a86c85cd"
     */
    token: string
  }
  
  export interface CartaoDeCredito {
    /**
     * Nome do portador do cartão
     * @example "Joao da silva"
     */
    nome: string
    /**
     * Ano e mês de vencimento do cartão
     * @example "2025-03"
     */
    dataDeVencimento: string
    /** @example "VISA" */
    bandeira: 'MASTERCARD' | 'VISA'
    /**
     * Numero do cartão
     * @example "4024007197692931"
     */
    numero: string
    /**
     * Primeiros seis digitos do cartão de crédito
     * @example "402400"
     */
    primeirosSeisDigitos?: string
    /**
     * Últimos quatro digitos do cartão de crédito.
     * @example 2931
     */
    ultimosQuatroDigitos?: string
    /**
     * cvv do cartão de crédito
     * @example "123"
     */
    cvv: string
  }
  
  export interface Fatura {
    /**
     * identificar único da fatura
     * @format uuid
     * @example "1ca38876-7e3a-44f6-b728-216cec4a59b3"
     */
    uuid?: string
    /**
     * status da fatura
     * @example "PAGA"
     */
    status?: 'PAGA' | 'PENDENTE' | 'CANCELADA'
    /**
     * Valor total dos descontos aplicados
     * @format decimal
     * @example "1078.80"
     */
    valorBase?: number
    /**
     * Valor total dos descontos aplicados
     * @format decimal
     * @example "215.76"
     */
    valorDoDesconto?: number
    /**
     * @format decimal
     * @example "863.04"
     */
    valor?: number
    /**
     * Indica a data de vencimento da fatura para pagamentos no boleto
     * @format date
     * @example "2021-06-01"
     */
    dataDoVencimento?: string
    /**
     * Data do pagamento da fatura
     * @format date
     * @example "2021-06-01"
     */
    dataDoPagamento?: string
    /**
     * Data e hora de criação da fatura
     * @format date-time
     * @example "2021-04-03T12:37:51"
     */
    dataDeCriacao?: string
    /**
     * Data e hora da última atualização na fatura
     * @format date-time
     * @example "2021-04-03T12:37:51"
     */
    dataDeAtualizacao?: string
    /**
     * Número de parcelas da fatura
     * @example 1
     */
    numeroDeParcelas?: number
    /**
     * Url de impressão do boleto
     * @example "https://pay.juno.com.br/charge/boleto.pdf?token=131209253:m:2e1fbd620a7696cc484c4082a741c6be154ad2f52642d26b05a955a446338514"
     */
    urlDoBoleto?: string
    /**
     * Indica se já foi emitido um recibo para a fatura
     * @example false
     */
    possuiRecibo?: boolean
    periodo?: Periodo
    modulos?: Modulo[]
    transacoes?: Transacao[]
  }
  
  export interface Transacao {
    /**
     * Identificador único da transação
     * @format uuid
     * @example "4e04fda6-20ad-4a57-be0b-e3808a22c094"
     */
    uuid?: string
    /**
     * Status da transação
     * @example "SUCESSO"
     */
    status?: 'SUCESSO' | 'REJEITADA'
    /** Tipo da transacao */
    meioDePagamento?: MeioDePagamento
    /**
     * Mensagem da transação definida pelo Prev
     * @example "Transação realizada com sucesso"
     */
    mensagem?: string
    /**
     * Código de resposta para a transação na adquirente.
     * @example "00"
     */
    codigoDeRespostaDoGateway?: string
    /**
     * Mensagem de respota para a transação na adquirente.
     * @example "Transacao capturada com sucesso"
     */
    mensagemDeRespostaDoGateway?: string
    /**
     * Data e hora de criação da fatura
     * @format date-time
     * @example "2021-04-03T12:37:51"
     */
    dataDeCriacao?: string
}